<template>
  <div class="invoice-page">
    <PageHeader :items="items" />
    <div class="row mb-1">
      <div class="col-md-6">
        <div class="d-flex align-items-center gap-2 form-group">
          <label class="form-label">{{ $t("Enter Coupon Code") }}</label>
          <TextInput
            class="m-0"
            :type="'text'"
            v-model="couponCode"
            :placeholder="''"
          />
          <button
            @click="getCouponCode()"
            :class="{ 'cursor-not-allowed': isFormInvalid }"
            class="btn-primary"
          >
            {{ $t("Activate") }}
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-for="(pkg, index) in packages" :key="index" class="col-lg-3">
        <b-card class="card-pricing h-100" align="center">
          <div>
            <h3>{{ pkg.name }}</h3>
            <b-card-text style="margin-top: 4px">
              {{
                $t(`You will get`) +
                " " +
                (parseFloat(pkg.credits) + parseFloat(freeCoins)).toFixed(2) +
                " " +
                $t(`DentalTwin Coins`)
              }}
            </b-card-text>
            <b-card-text
              v-if="pkg.freeCredits && pkg.freeCredits !== '0.00'"
              style="margin-top: 6px; color: red"
            >
              {{
                $t(`+ `) +
                " " +
                parseFloat(pkg.freeCredits).toFixed(2) +
                " " +
                $t(`free DentalTwin Coins`)
              }}
            </b-card-text>
            <div class="mt-1" v-if="pkg.image && pkg.image.url">
              <img style="width: 140px; height: 100px" :src="pkg.image.url" />
            </div>
          </div>
          <div>
            <div class="annual-plan">
              <div class="plan-price">
                <!-- <sup class="font-medium-1 font-weight-bold">$</sup> -->
                <span class="pricing-basic-value font-weight-bolder">{{
                  $formatter(pkg.costs)
                }}</span>
                <sub
                  class="pricing-duration text-body font-medium-1 font-weight-bold"
                ></sub>
              </div>
            </div>

            <!-- buttons -->
            <b-button
              @click="stripeCheckout(pkg.id)"
              block
              class="mt-1 btn btn-primary"
            >
              {{ $t("Buy") }}
            </b-button>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
// import CookieButton from "@/components/elements/CookieButton.vue";
import ElementIcon from "@/components/elements/Icon.vue";
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
import TextInput from "@/components/TextInput.vue";
import NotificationService from "../../../services/notification.service";

export default {
  components: {
    ElementIcon,
    PageHeader,
    TextInput,
    // CookieButtons,
  },
  computed: {
    ...mapGetters("packages", ["packages"]),

    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("DentalTwin Coins"),
          active: true,
        },
      ];
    },
    isFormInvalid() {
      return this.couponCode.length === 0;
    },
  },
  data() {
    return {
      couponCode: "",
      freeCoins: 0.0,
      couponCodeId: "",
    };
  },
  created() {
    this.$store.commit("showLoader", true);
    this.loadItems();
  },

  methods: {
    async loadItems() {
      await this.$store
        .dispatch("packages/list")
        .then((res) => {
          this.$store.commit("showLoader", false);
        })
        .finally(() => {
          this.$store.commit("showLoader", false);
        });
    },
    async stripeCheckout(id) {
      this.$store.commit("showLoader", true);
      await this.$store
        .dispatch("packages/stripeCheckout", {
          packageId: id,
          email: localStorage.getItem("email"),
          couponCodeId: this.couponCodeId,
        })
        .then((res) => {
          this.$store.commit("showLoader", false);
          window.location.href = res?.data?.url;
        })
        .finally(() => {
          this.$store.commit("showLoader", false);
        });
    },
    async getCouponCode() {
      this.couponCodeId = "";
      this.$store.commit("showLoader", true);
      await this.$store
        .dispatch("packages/getCouponCode", this.couponCode)
        .then((res) => {
          this.freeCoins = res?.data?.data?.freeUsers ?? 0.0;
          this.couponCodeId = res?.data?.data?.id ?? "";
          NotificationService.showInfo("Coupon Code Applied Successfully");
        })
        .finally(() => {
          this.$store.commit("showLoader", false);
        });
    },
    async loadItems() {
      await this.$store
        .dispatch("packages/list")
        .then((res) => {
          this.$store.commit("showLoader", false);
        })
        .finally(() => {
          this.$store.commit("showLoader", false);
        });
    },
  },

  beforeRouteEnter(to, from, next) {
    document.body.classList.add("cookie-layout");
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove("cookie-layout");
    next();
  },
};
</script>
<style lang="scss">
.card-pricing {
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .plan-price {
      color: #05fffa;
      .pricing-basic-value {
        font-size: 26px;
      }
    }
  }
}
</style>
